import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdBannerGeneral from "../components/adBannerGeneral";

const AboutContent = (props) => {
	const totalUnread = useSelector(state => state.totalUnread);
	const plusMember = useSelector(state => state.plusMember);


	return (
		<div className="pageWrapper page textPage">
			<SEO
				title={totalUnread == 0 ? props.intl.formatMessage({ id: 'about' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
				description={props.intl.formatMessage({ id: 'aboutSeoDesc' })}
				pagePath="/about"
			/>

			<h1><FormattedMessage id="about" /></h1>

			<div className="dividerHr">
				<span className="dividerSpan"></span>
			</div>

			<br />
			<h5 className="primaryColor">Väkommen att läsa och diskutera nyheter fritt - utan censur</h5>
			<br />

			<div className="aboutPage">
				<h3>Ta pulsen på Sverige</h3>

				<p>
					På Dinanyheter bestämmer du nyheterna och kan ta pulsen på nuläget i Sverige och diskutera de frågor du brinner för mest med andra engagerade från hela landet.
				</p>

				<p>
					Hela webbplatsen är mobilanpassad och kan användas på alla enheter, såsom datorer, surfplattor och telefoner.
				</p>

				<p>
					Ingen registrering krävs för att läsa nyheter och diskussioner, vill du rösta eller delta i diskussionerna kan du bli medlem på några sekunder - helt gratis såklart.
				</p>

				<h3>Nyheter från många olika källor</h3>

				<p>
					Då nyheter och inlägg skapas av användarna själva får du din information från flera olika källor, och behöver inte oroa dig lika mycket för att tidningen du läser vinklar nyheterna.
				</p>

				<p>
					Alla inlägg på Dinanyheter kan diskuteras direkt så det är lätt att få mer information om något man är intresserad av.
				</p>

				<p>
					Både nyheter, inlägg och kommentarer kan röstas upp och ned av alla användare. På så sätt kan du visa vad just du tycker och är intresserad av, samt ser vad andra användare anser vara viktigast.
				</p>


				<h3>Alla kan bidra</h3>

				<p>
					Grundtanken är att ordet ska vara helt fritt på Dinanyheter, utan onödiga ingrepp och censur. Allt vi ber om är att alla följer svensk lag.
				</p>

				<p>Här är det folket som bestämmer vad som visas högst upp och kan säga precis vad de tycker.</p>

				<p><FormattedMessage id="allForFree" /></p>
				<br /><br />
			</div>

		</div>
	)
}

export default injectIntl(AboutContent);